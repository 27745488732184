<template>
  <header class="topbar">
    <b-navbar
        toggleable="lg"
        :type="`${navbarColor === 'white' ? 'light' : 'dark'}`"
        class="p-0"
        variant="white"
    >
      <b-navbar-nav>
        <b-nav-item @click="showMobileSidebar" class="d-block d-lg-none">
          <i class="mdi mdi-menu fs-3" style="color: var(--secondary-color);"></i>
        </b-nav-item>
      </b-navbar-nav>
      <div :class="`navbar-header d-block d-lg-none`">
        <LogoDark v-if="navbarColor === 'white'"/>
        <LogoLight v-else/>
      </div>
      <b-navbar-toggle target="nav-collapse" class="mx-2 border-0">
        <i class="ti-more" style="color: var(--secondary-color);"></i>
      </b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav class="">
        <!-- -----------------------------------------------------------
          Left aligned nav items
        ----------------------------------------------------------- -->
        <b-navbar-nav>
          <!-- -----------------------------------------------------------
            Toggle Sidebar
          ----------------------------------------------------------- -->
          <b-nav-item @click="setMiniSidebar" class="d-none d-lg-block">
            <i class="mdi mdi-menu fs-3" style="color: var(--secondary-color);"></i>
          </b-nav-item>
        </b-navbar-nav>

        <b-navbar-nav class="ml-auto" v-if="innerWidth > mobileWidth">
          <div class="ml-auto">
            <div class="grid-nav-bar">
              <div class="flex-center">
                <language @closeURLCredit="closeURLCredit" require-url-credit :close-dropdown-prop="showURL"/>
              </div>
              <div class="flex-center" v-if="checkPermission('create', 'action', 'url-tracking-premium')">
                <div class="border-left-bar">&nbsp;</div>
                <div class="image-icon-balance flex-middle tracking-two-pro">
                  <img src="@/assets/images/icon/nav-crown2pro.svg" alt="link-url">
                </div>
                <div class="width-bar" id="total-tracking-2pro">
                  <div class="text-title">Tracking2Pro</div>
                  <div class="text-total" style="color: #F25B5B">{{
                      changeNumberFormat(tracking_2pro, 'tracking2Pro')
                    }}
                  </div>
                </div>
                <b-tooltip target="total-tracking-2pro" placement="top" v-if="tracking2Pro">
                  {{ tooltipChangeNumberFormat(tracking_2pro) }}
                </b-tooltip>
              </div>
              <div class="flex-center">
                <div class="border-left-bar">&nbsp;</div>
                <div class="image-icon-balance custom-gradient-primary flex-middle">
                  <img src="@/assets/images/icon/wallet.svg" alt="wallet">
                </div>
                <div class="width-bar" id="total-credit">
                  <div class="text-title">{{ $t('navbar.credit_balance') }}</div>
                  <div class="text-total text-primary">{{ changeNumberFormat(total, 'total_credit') }}</div>
                </div>
                <b-tooltip target="total-credit" placement="top" v-if="total_credit">
                  {{ tooltipChangeNumberFormat(total) }}
                </b-tooltip>
                <div class="add-credit flex-middle " @click="topUp">
                  <img src="@/assets/images/icon/shopping-cart.svg" alt="shopping-cart">
                </div>
              </div>
              <div class="flex-center">
                <div class="border-left-bar" style="padding-left: 0;">&nbsp;</div>
                <b-nav-item-dropdown right no-caret class="mailbox" ref="dropdown_profile">
                  <template #button-content>
                    <div class="flex-center" style="height: 100%;" @click="showURL = false">
                      <b-avatar id="tooltip-target-1" v-if="!profileImg" src="@/assets/images/users/1.jpg" badge badge-buttom badge-offset="-6px" class="badge-verify">
                        <template #badge>
                          <img v-if="userInfo?.identity_verify_status === 'verified'" src="@/assets/images/icon/verified.svg" width="20" alt="shopping-cart">
                          <img v-else src="@/assets/images/icon/not_verify.svg" width="20" alt="shopping-cart">
                        </template>
                      </b-avatar>
                      <b-avatar id="tooltip-target-1" v-else :src="profileImg" badge badge-buttom badge-offset="-6px" class="badge-verify">
                        <template #badge>
                          <img v-if="userInfo?.identity_verify_status === 'verified'" src="@/assets/images/icon/verified.svg" width="20" alt="shopping-cart">
                          <img v-else src="@/assets/images/icon/not_verify.svg" width="20" alt="shopping-cart">
                        </template>
                      </b-avatar>
                      <div class="ml-3 custom-profile-nav">
                        <div class="text-grey email-profile">
                          {{ userInfo?.email }}
                          <div class="font-9" :class="connectionStatus ? 'text-success': 'text-danger'">
                            {{connectionStatus ? $t('common.connected') : $t('common.lost_connection') }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <b-dropdown-text class="bg-primary text-white">
                    <div class="d-flex align-items-center py-2">
                      <b-img v-if="!profileImg" src="@/assets/images/users/1.jpg" width="50" rounded="circle"/>
                      <b-img v-else :src="profileImg" width="50" height="50" rounded="circle"/>
                      <div class="ml-3">
                        <h4 class="mb-0">
                          {{ setFullName(name) }}
                        </h4>
                        <span class="font-weight-light">{{ userInfo?.email }}</span>
                      </div>
                    </div>
                  </b-dropdown-text>
                  <b-dropdown-item href="#" class="d-flex align-items-center custom-dropdown-item"
                                   @click="settingAccount">
                    <feather type="user" class="feather-sm mr-2 mb-n1"></feather>
                    {{ $t('navbar.profile') }}
                  </b-dropdown-item>
                  <b-dropdown-item href="#" class="d-flex align-items-center custom-dropdown-item" @click="Logout">
                    <feather type="log-out" class="feather-sm mr-2 mb-n1"></feather>
                    {{ $t('navbar.signout') }}
                  </b-dropdown-item>
                </b-nav-item-dropdown>
              </div>
            </div>
          </div>
        </b-navbar-nav>
        <b-navbar-nav v-else>
          <div>
            <b-list-group id="collapse-nav-mobile" flush>
              <b-list-group-item>
                <div>
                  <language/>
                </div>
              </b-list-group-item>
              <!-- Tracking2PRO -->
              <b-list-group-item v-if="checkPermission('create', 'action', 'url-tracking-premium')">
                <div class="d-flex m-2">
                  <div class="image-icon-balance tracking-two-pro flex-middle">
                    <img src="@/assets/images/icon/nav-crown2pro.svg" alt="link-url">
                  </div>
                  <div class="width-bar">
                    <div class="text-title">Tracking2Pro</div>
                    <div class="text-total " style="color: #F25B5B">
                      {{ tooltipChangeNumberFormat(tracking_2pro) }}
                    </div>
                  </div>
                </div>
              </b-list-group-item>
              <!-- end -->
              <!-- URLTracking -->
              <b-list-group-item v-if="checkPermission('list', 'action', 'url-trackings') && url_tracking > 0">
                <div class="d-flex m-2">
                  <div class="image-icon-balance custom-url-tracking flex-middle">
                    <img src="@/assets/images/icon/link-url.svg" alt="link-url">
                  </div>
                  <div class="width-bar">
                    <div class="text-title">{{ $t('navbar.url_tracking') }}</div>
                    <div class="text-total text-info">{{ tooltipChangeNumberFormat(url_tracking) }}</div>
                  </div>
                </div>
              </b-list-group-item>
              <!-- end -->
              <b-list-group-item>
                <div class="d-flex m-2 justify-content-between">
                  <div class="d-flex" id="total-credit">
                    <div class="image-icon-balance custom-gradient-primary flex-middle">
                      <img src="@/assets/images/icon/wallet.svg" alt="wallet">
                    </div>
                    <div class="width-bar">
                      <div class="text-title">{{ $t('navbar.credit_balance') }}</div>
                      <div class="text-total text-primary">{{ tooltipChangeNumberFormat(total) }}</div>
                    </div>
                  </div>
                  <div class="add-credit flex-middle mt-2" @click="topUp">
                    <img src="@/assets/images/icon/shopping-cart.svg" alt="shopping-cart">
                  </div>
                </div>
              </b-list-group-item>
              <b-list-group-item>
                <div>
                  <b-nav-item-dropdown id="mailbox-mobile" right no-caret class="mailbox">
                    <template #button-content>
                      <div class="flex-center" style="height: 100%;">
                        <b-avatar id="tooltip-target-1" v-if="!profileImg" src="@/assets/images/users/1.jpg" badge badge-buttom badge-offset="-6px" class="badge-verify">
                          <template #badge>
                            <img v-if="userInfo?.identity_verify_status === 'verified'" src="@/assets/images/icon/verified.svg" width="20" alt="shopping-cart">
                            <img v-else src="@/assets/images/icon/not_verify.svg" width="20" alt="shopping-cart">
                          </template>
                        </b-avatar>
                        <b-avatar id="tooltip-target-1" v-else :src="profileImg" badge badge-buttom badge-offset="-6px" class="badge-verify">
                          <template #badge>
                            <img v-if="userInfo?.identity_verify_status === 'verified'" src="@/assets/images/icon/verified.svg" width="20" alt="shopping-cart">
                            <img v-else src="@/assets/images/icon/not_verify.svg" width="20" alt="shopping-cart">
                          </template>
                        </b-avatar>

                        <div class="ml-3 custom-profile-nav">
                          <div class="text-grey email-profile">
                            {{ userInfo?.email }}
                            <div class="font-9" :class="connectionStatus ? 'text-success': 'text-danger'">
                              {{connectionStatus ? $t('common.connected') : $t('common.lost_connection') }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                    <b-dropdown-text class="bg-primary text-white">
                      <div class="d-flex align-items-center py-2">
                        <b-img v-if="!profileImg" src="@/assets/images/users/1.jpg" width="50" rounded="circle"/>
                        <b-img v-else :src="profileImg" width="50" height="50" rounded="circle"/>
                        <div class="ml-3">
                          <h4 class="mb-0">
                            {{ setFullName(name) }}
                          </h4>
                          <span class="font-weight-light">{{ userInfo?.email }}</span>
                        </div>
                      </div>
                    </b-dropdown-text>
                    <b-dropdown-item href="#" class="d-flex align-items-center custom-dropdown-item"
                                     @click="settingAccount">
                      <feather type="user" class="feather-sm mr-2 mb-n1"></feather>
                      {{ $t('navbar.profile') }}
                    </b-dropdown-item>
                    <b-dropdown-item href="#" class="d-flex align-items-center custom-dropdown-item" @click="Logout">
                      <feather type="log-out" class="feather-sm mr-2 mb-n1"></feather>
                      {{ $t('navbar.signout') }}
                    </b-dropdown-item>
                  </b-nav-item-dropdown>
                </div>
              </b-list-group-item>
            </b-list-group>
          </div>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </header>
</template>

<script>
import {mapState} from 'vuex'
import LogoDark from '../logo-dark/LogoDark'
import LogoLight from '../logo-light/LogoLight'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import functionsCommon from "@/common/functions";
import img1 from '../../../assets/images/users/1.jpg'
import img2 from '../../../assets/images/users/2.jpg'
import img3 from '../../../assets/images/users/3.jpg'
import img4 from '../../../assets/images/users/4.jpg'
import Language from '@/components/common/Language'
import functions from "@/common/functions";
export default {
  name: 'vertical-header',
  data: () => ({
    showURL: false,
    tracking2Pro: false,
    urlTracking: false,
    total_credit: false,
    notifications: [
      {
        btnbg: 'danger',
        btnicon: 'user',
        title: 'Launch Admin',
        subtext: 'Just see the my new admin!',
        time: '9:30AM',
      },
      {
        btnbg: 'success',
        btnicon: 'calendar',
        title: 'Event today',
        subtext: 'Just a reminder that you have event',
        time: '1:30PM',
      },
      {
        btnbg: 'primary',
        btnicon: 'settings',
        title: 'Settings',
        subtext: 'You can customize this template as you want',
        time: '9:30AM',
      },
      {
        btnbg: 'info',
        btnicon: 'bell',
        title: 'New Message',
        subtext: 'Sent you an notification',
        time: '1:30PM',
      },
    ],
    messages: [
      {
        btnbg: img1,
        badgebg: 'danger',
        title: 'Launch Admin',
        subtext: 'Just see the my new admin!',
        time: '9:30AM',
      },
      {
        btnbg: img2,
        badgebg: 'success',
        title: 'Event today',
        subtext: 'Just a reminder that you have event',
        time: '1:30PM',
      },
      {
        btnbg: img3,
        badgebg: 'primary',
        title: 'Settings',
        subtext: 'You can customize this template as you want',
        time: '9:30AM',
      },
      {
        btnbg: img4,
        badgebg: 'warning',
        title: 'New Message',
        subtext: 'Sent you an notification',
        time: '1:30PM',
      },
    ],
    href() {
      return undefined
    },
  }),
  components: {
    Language,
    LogoDark,
    LogoLight,
    VuePerfectScrollbar
  },
  computed: {
    ...mapState({
      navbarColor: (state) => state.theme.navbarColor,
      logoColor: (state) => state.theme.logoColor,
      LayoutType: (state) => state.theme.LayoutType,
      connectionStatus: (state) => state.profile.online_socket,
      innerWidth: state => state.theme.width_inner,
      mobileWidth: state => state.theme.mobile_width,
    }),
    userInfo() {
      return this.$store.getters["profile/getProfile"]
    },
    name() {
      return this.$store.getters["profile/getName"]
    },
    profileImg() {
      return this.$store.getters["profile/getProfileImg"]
    },
    total() {
      return this.$store.getters["profile/getCredit"]
    },
    url_tracking() {
      return this.$store.getters["profile/getURLTracking"]
    },
    tracking_2pro() {
      return this.$store.getters["profile/getTracking2PRO"]
    }
  },
  methods: {
    closeURLCredit(param) {
      this.showURL = param
    },
    stopPropagation(event) {
      event.stopPropagation();
      this.showURL = !this.showURL
    },
    closeDivOnClickOutside(event) {
      if (this.showURL && !this.$refs.selectContainer.contains(event.target)) {
        this.showURL = false
      }
    },
    checkPermission(permission, type, modules) {
      return functions.userCheckPermission(permission, type, modules)
    },
    tooltipChangeNumberFormat(data) {
      return data ? functionsCommon.changeNumberFormat(data) : '0'
    },
    changeNumberFormat(number, type) {
      if (number) {
        if (number >= 1000000) {
          const millionValue = Math.floor((number / 1000000) * 100) / 100;
          if (type === 'total_credit') {
            this.total_credit = true
          } else if (type === 'urlTracking') {
            this.urlTracking = true
          } else if (type === 'tracking2Pro') {
            this.tracking2Pro = true
          }
          return millionValue.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) + 'M';
        } else {
          if (type === 'total_credit') {
            this.total_credit = false
          } else if (type === 'urlTracking') {
            this.urlTracking = false
          } else if (type === 'tracking2Pro') {
            this.tracking2Pro = false
          }
          return parseFloat(number).toLocaleString();
        }
      } else {
        return '0'
      }
    },
    showMobileSidebar() {
      this.$store.commit('theme/SET_SIDEBAR_DRAWER', true)
    },
    setMiniSidebar() {
      if (this.LayoutType === 'full-sidebar') {
        this.$store.dispatch('theme/setLayoutType', 'mini-sidebar')
      } else if (this.LayoutType === 'mini-sidebar') {
        this.$store.dispatch('theme/setLayoutType', 'full-sidebar')
      }
    },
    Logout() {
      const prefix = this.$route.params.id
      localStorage.removeItem('profile')
      localStorage.removeItem('token')
      sessionStorage.clear()
      this.$router.push('/Login')
      this.removeCookie('ACCEPT_TUTORIAL')

    },
    topUp() {
      if (this.userInfo?.current_plan_id) {
        this.$router.push('/Subscriptions').catch(() => {
        })
      } else {
        this.$router.push('/Subscriptions/Package').catch(() => {
        })
      }
    },
    settingAccount() {
      this.$router.push('/Account').catch(() => {
      })
    },
    setFullName(name) {
      return _.truncate(name, {'length': 25, 'omission': '...'});
    },
    removeCookie(name) {
      return functionsCommon.deleteCookie(name)
    }
  },
  mounted() {
    document.addEventListener('click', this.closeDivOnClickOutside);
  },
  beforeDestroy() {
    // Remove the click event listener when the component is destroyed
    document.removeEventListener('click', this.closeDivOnClickOutside);
  },
  watch: {
    showURL(val) {
      if (val) {
        this.$refs.dropdown_profile.hide(false)
      }
    }
  }
}
</script>
<style lang="scss">
.custom-gradient-primary {
  background: linear-gradient(180deg, rgba(var(--secondary-color-rgb), 0.8) 35%, rgba(var(--primary-color-rgb), 0.6) 100%) !important;
}

.tracking-two-pro {
  background: rgb(242, 91, 91);
  background: linear-gradient(180deg, rgba(242, 91, 91, 1) 35%, rgba(255, 208, 69, 1) 100%);
}

.custom-url-tracking {
  background: rgb(64, 60, 222);
  background: linear-gradient(180deg, rgba(64, 60, 222, 1) 35%, rgba(86, 180, 221, 1) 100%);
}

.text-grey {
  color: #586a8f !important;
}

.full-total-credit {
  min-width: 440px;
}

.mini-total-credit {
  min-width: 440px;
}

.total-credit {
  border-radius: 5px;
  margin: 3px;
  color: #6d7d9d;
  padding-left: 5px;
  padding-right: 5px;

}

.custom-dropdown-item {
  border-bottom: 1px solid #e9ecef;

  &:last-child {
    border-bottom: 0;
  }

  .dropdown-item {
    padding: 0.85rem 1rem;
  }
}
</style>
<style lang="scss" scoped>
.url-container {
  position: relative;

  .normal-caret {
    transform: rotate(0deg);
    transition: transform 0.2s ease-in;
  }

  .rotate-caret {
    transform: rotate(180deg);
    transition: transform 0.2s ease-in;
  }

  .select-url {
    cursor: pointer;
    border-radius: 6px;
    padding: 4px 4px 4px 8px;
    margin-right: 12px;

    .caret {
      padding: 0 4px;
      margin: 4px 4px 4px 12px;
      border-radius: 6px;
      background: #FFFFFFFF;
    }
  }

  #default-item-position-url-navbar {
    bottom: -123px;
  }

  #one-item-position-url-navbar {
    bottom: -65px;
  }

  .url-content {
    position: absolute;
    z-index: 99;
    padding: 4px;
    border-radius: 6px;
    width: calc(100% + 80px);
    left: -90px;
    background: #FFFFFFFF;
    opacity: 0;
    transition: opacity 0.2s ease-in; /* Adjust the duration and timing function as needed */
  }

  .url-content-show {
    opacity: 1;
    transition: opacity 0.2s ease-in;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.10);
  }
}


.width-bar {
  padding: 0 16px;
}

.text-title {
  font-size: 12px;
  @media (max-width: 768px) {
    font-size: 14px !important;
  }
}

.text-total {
  font-size: 20px;
  margin-bottom: 0px;
  font-weight: 700;
  line-height: 1.4rem;
}

.grid-nav-bar {
  // display: grid;
  display: flex;
  // grid-template-columns: 2fr 3fr 3fr 2fr;
  max-width: 900px;
  // padding: 10px 0;
}

.border-left-bar {
  border-left: 1px solid #E9E9E9;
  padding-left: 16px;
  height: 40px;
}

.image-icon-balance {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  color: #fff;
  font-size: 18px;

  img {
    width: 22px;
    height: 22px;
  }
}

.add-credit {
  background: linear-gradient(180deg, rgba(var(--secondary-color-rgb), 1) 35%, rgba(var(--primary-color-rgb), 1) 100%) !important;
  border-radius: 100px;
  color: #ffffff;
  cursor: pointer;
  width: 28px;
  height: 28px;
  margin: 0 16px 0 0;
  font-size: 12px;
  transition: 0.3s;

  img {
    width: 18px;
    height: 18px;
  }

  &:hover {
    opacity: 0.85;
  }
}

.bg-secondary {
  background-color: var(--secondary-color) !important;
}

.tooltip-connect {

}

.badge-verify::v-deep  .b-avatar-badge {
  background-color: transparent;
}
.email-profile {
  line-height: 15px;
}

@media (max-width: 768px) {
  .topbar .navbar-collapse {
    display: block;
    align-items: unset;
    box-shadow: 2px 0 4px rgba(0, 0, 0, 0.1);
  }
  .topbar .navbar-nav {
    flex-direction: column;
  }
  #collapse-nav-mobile::v-deep .list-group-item {
    position: relative;
    display: block;
    padding: 0;
  }
  #mailbox-mobile::v-deep .nav-link {
    padding: 0px 8px !important;
  }
}
</style>