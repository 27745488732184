<template>
  <b-navbar-brand>
    <!-- Logo icon -->
    <b class="logo-icon">
      <!-- Dark Logo icon -->
      <img v-show="BrandSetting.image.logo"
           :src="BrandSetting.image.logo"
           alt="Logo" height="40px"
      />
    </b>
    <!--End Logo icon -->
    <!-- Logo text -->
    <span class="logo-text">
            <!-- dark Logo text -->
          <img v-show="BrandSetting.image.text_logo"
               :src="BrandSetting.image.text_logo"
               alt="Logo" height="40px"
          />


        </span>
  </b-navbar-brand>
</template>

<script>
export default {
  name: 'LogoLight',
  computed: {
    BrandSetting() {
      return JSON.parse(localStorage.getItem('brand_setting'))
    },
  },
}
</script>
