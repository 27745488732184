<template>
  <div :class="`${LayoutType} ${Theme}`">
    <!-- ---------------------------------- -->
    <!--- Vertical Header part -->
    <!-- ---------------------------------- -->
    <VerticalSidebar v-if="!setHorizontalLayout"/>
    <aside :class="setHorizontalLayout === true ? 'horizontal-page-wrapper' : 'page-wrapper'">
      <VerticalHeader v-if="!setHorizontalLayout"/>
      <HorizontalHeader v-if="setHorizontalLayout"/>
      <HorizontalSidebar v-if="setHorizontalLayout"/>
      <div class="min-height" :class="setHorizontalLayout == true ? 'container-fluid p-4' : 'container-fluid p-4'">
        <!--        <h4 v-if="$route.path !== '/starter'">{{ $route.name }}</h4>-->
        <!--        <b-breadcrumb class="p-0" v-if="$route.path !== '/starter'">-->
        <!--          <b-breadcrumb-item href="/" custom> Home</b-breadcrumb-item>-->
        <!--          <b-breadcrumb-item active>{{ $route.name }}</b-breadcrumb-item>-->
        <!--        </b-breadcrumb>-->
        <router-view/>
      </div>
      <!--      <float-button v-if="!checkMobile"/>-->
      <!--      <Customizer/>-->
      <Footer/>
    </aside>
    <modal-free-package-component :modal="block_modal" :user_state="user_state" id="modal-top-bar"
                                  @closeModal="closeModal" v-if="preventReload()">
      <template v-slot:header>
        <img :src="checkIconFreePackageState" alt="status-img" width="73px" height="73px">
        <p class="font-size-title" style="text-align: center;" :class="{'text-danger': userExpired}">
          {{ packageDescription }}
        </p>

      </template>
      <template v-slot:content>
        <div class="content-modal__content-tob-bar" v-if="!userLess">
          <div v-if="!userEmpty" class="content-modal">
            <div class="content-modal-left__detail" v-if="userFresh || userRemainder || userFreshNew">
              <p class="font-break-point">{{ $t('report.creditTopUp.list.amount') }}</p>
              <p class="font-break-point">:
                <strong class="text-primary">{{ smsUnit?.toLocaleString() || 0 }}</strong> {{
                  $t('subscriptions.credit')
                }}
              </p>
            </div>
            <div class="content-modal-left__detail">
              <p class="font-break-point" v-if="!userLess">{{ $t('freeTrials.remainingAge') }}</p>
              <p class="font-break-point" v-if="userFresh || userFreshNew">:
                <strong :class="{'text-danger': !userExpired}">168 {{ $t('freeTrials.hours') }}</strong>
                <span style="color: #9F9F9F">{{ `(7 ${$t('button.day')})` }}</span>
              </p>
              <p class="font-break-point" v-if="userRemainder">:
                <span v-if="less_day || less_hour"> {{ $t('freeTrials.lessThan') }} </span>
                <strong :class="{'text-danger': !userExpired}">{{ timeExpiredHourAt }}</strong>
                <span>{{ $t('freeTrials.hours') }}</span>
                <span style="color: #9F9F9F" v-if="!less_day && !less_hour">{{
                    `(${timeExpiredAt} ${$t('button.day')})`
                  }}</span>
              </p>
            </div>
            <div class="content-modal-left__detail" v-if="userFresh || userFreshNew">
              <p class="font-break-point">{{ $t('freeTrials.expired') }}</p>
              <p class="font-break-point">: {{ formatExpiredAt }}</p>
            </div>
          </div>
          <p v-else class="content-modal-empty">{{ $t('freeTrials.emptyCredit') }}</p>
        </div>
        <p v-else class="less-thousand-credit">{{ $t('freeTrials.subLessCreditFreeTrials') }}</p>
      </template>
      <template v-slot:action>
        <div v-if="userFresh || userFreshNew" class="action-layout">
          <div class="button-fresh-layout">
            <button
                class="btn-breakpoint-fresh btn btn-outline"
                style="padding: 12px 32px;cursor: pointer;"
                variant="gradient"
                :disabled="loading_trial"
                @click="actionModalByPackage()"
            >
              {{ $t('button.byPackage') }}
              <b-spinner small v-show="loading_by_package"/>
            </button>
            <b-button
                class="btn-breakpoint-fresh"
                style="padding: 12px 32px;cursor: pointer;"
                variant="gradient"
                :disabled="loading_by_package"
                @click="actionModalTrial()"
            >
              {{ $t('button.trial') }}
              <b-spinner small v-show="loading_trial"/>
            </b-button>
          </div>
        </div>
        <div v-else class="action-layout">
          <label for="close_all_day" class="checkout-close-all-day">
            <input type="checkbox" id="close_all_day" v-model="close_all_day">
            <span class="label-text">{{ $t('freeTrials.closeAllDay') }}</span>
          </label>
          <div class="button-other-layout">
            <b-button
                class="btn-breakpoint"
                style="padding: 12px 32px;cursor: pointer;"
                variant="gradient"
                @click="actionModalByPackage()"
            >
              {{ $t('button.byPackage') }}
              <b-spinner small v-show="loading_by_package"/>
            </b-button>
          </div>
        </div>
        <div class="action-modal-hint" v-if="userFresh">
          <p class="action-modal-left">
            {{ $t('freeTrials.note') }}
          </p>
          <p class="action-modal-right">{{ $t('freeTrials.onlyFree') }}
            <span class="text-danger-modal text-bold">{{ $t('freeTrials.quickSend') }}</span>
          </p>
        </div>
      </template>
    </modal-free-package-component>
  </div>
</template>

<script>
import VerticalHeader from './vertical-header/VerticalHeader'
import HorizontalHeader from './horizontal-header/HorizontalHeader'
import HorizontalSidebar from './horizontal-sidebar/HorizontalSidebar'
import VerticalSidebar from './vertical-sidebar/VerticalSidebar'
import Customizer from './Customizer/Customizer'
import Footer from './footer/Footer'
import {mapState} from 'vuex'
import FloatButton from "@/views/floatButton";
import ModalFreePackageComponent from "@/components/freeTrial/modalFreePackageComponent.vue";
import authApi from "@/repository/authApi";
import profileApi from "@/repository/profileApi"
import moment from "moment/moment";
import {isAfter} from 'date-fns'
import {store} from "@/store";
import tr from "vue2-datepicker/locale/es/tr";

export default {
  name: 'FullLayout',
  components: {
    ModalFreePackageComponent,
    FloatButton,
    VerticalHeader,
    VerticalSidebar,
    Customizer,
    HorizontalSidebar,
    HorizontalHeader,
    Footer
  },
  data: () => ({
    // cpath: this.currentRoute.path,
    showToggle: false,
    loading_by_package: false,
    loading_trial: false,
    modal: false,
    close_all_day: false,
    block_modal: true,
    is_using_trial: null,
    sms_unit: null,
    expired_at: null,
    less_day: false,
    less_hour: false,
    user_state: null,
    is_notify_plan_expire: true,
    is_notify_plan_almost_expire: true,
    is_notify_out_of_credit: true,
    is_notify_credit_below_1000: true,
    has_previous_plan: false,
    items: [
      {
        text: 'Dashboard',
        href: '/'
      },
      {
        text: '',
        active: true
      }
    ],
  }),
  computed: {
    ...mapState({
      SMSRoute: state => state.profile.smsRoute,
      Theme: state => state.theme.Theme,
      LayoutType: state => state.theme.LayoutType,
      setHorizontalLayout: state => state.theme.setHorizontalLayout,
    }),
    checkMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    currentRouteName() {
      return this.$route.name;
    },
    userLess() {
      return this.user_state === 'less'
    },
    userEmpty() {
      return this.user_state === 'empty'
    },
    userRemainder() {
      return this.user_state === 'remainder'
    },
    userFresh() {
      return this.user_state === 'fresh'
    },
    userFreshNew() {
      return this.user_state === 'fresh_new'
    },
    userExpired() {
      return this.user_state === 'expired'
    },
    packageDescription() {
      let description = {
        fresh_new: 'freeTrials.newUserFreeTrials',
        fresh: 'freeTrials.newUserFreeTrials',
        remainder: 'freeTrials.remainCreditUserFreeTrials',
        expired: 'freeTrials.expiredCreditUserFreeTrials',
        empty: 'freeTrials.emptyCreditFreeTrials',
        less: 'freeTrials.lessCreditFreeTrials'
      }
      return this.$t(description[this.user_state])
    },
    smsUnit() {
      const profile = JSON.parse(localStorage.getItem('profile'))
      const sms_unit = _.isNil(this.sms_unit) || _.isUndefined(this.sms_unit) ? profile.sms_unit : this.sms_unit
      return sms_unit
    },
    formatExpiredAt() {
      const profile = JSON.parse(localStorage.getItem('profile'))
      const expired_at = _.isNil(this.expired_at) || _.isUndefined(this.expired_at) ? profile.expire_at : this.expired_at
      return moment(new Date(expired_at)).format('DD/MM/YYYY')
    },
    timeExpiredAt() {
      const profile = JSON.parse(localStorage.getItem('profile'))
      const expired_at = _.isNil(this.expired_at) || _.isUndefined(this.expired_at) ? profile.expire_at : this.expired_at
      const date = new Date()
      const expire = new Date(expired_at)
      const difference_time = expire.getTime() - date.getTime();
      let difference_day = difference_time / (1000 * 3600 * 24);
      difference_day % 1 != 0 ? difference_day = Math.floor(difference_day) + 1 : difference_day
      return difference_day
    },
    timeExpiredHourAt() {
      const profile = JSON.parse(localStorage.getItem('profile'))
      const date = new Date()
      const expired_at = _.isNil(this.expired_at) || _.isUndefined(this.expired_at) ? profile.expire_at : this.expired_at
      const expire = new Date(expired_at)
      const temp_expired_at = moment([expire.getFullYear(), expire.getMonth() + 1, expire.getDate(), expire.getHours()])
      const current_date = moment([date.getFullYear(), date.getMonth() + 1, date.getDate(), date.getHours()])
      const result = temp_expired_at.diff(current_date, 'hours')
      if (result < 24) {
        this.less_day = true
        if (result < 1) {
          this.less_day = false
          this.less_hour = true
        }
      }
      return this.less_hour ? '1' : result
    },
    checkIconFreePackageState() {
      let selectColor = {
        fresh_new: require('@/assets/images/icon/firework.svg'),
        fresh: require('@/assets/images/icon/firework.svg'),
        remainder: require('@/assets/images/icon/remainder.svg'),
        expired: require('@/assets/images/icon/package-expried.svg'),
        empty: require('@/assets/images/icon/mail.svg'),
        less: require('@/assets/images/icon/less.svg'),
      }
      return selectColor[this.user_state]
    }
  },
  methods: {
    preventReload() {
      authApi.getCustomerProfile().then((res) => {
        const not_null_current_plan_expired_at = !_.isNil(res?.current_plan_id) && !_.isNil(res?.expire_at)
        const profile = JSON.parse(localStorage.getItem('profile'))
        let trigger_remainder = false
        let already_expired = false
        let block_modal = JSON.parse(localStorage.getItem('block_modal'))
        let once_modal = JSON.parse(localStorage.getItem('once_modal'))
        this.is_notify_credit_below_1000 = res?.is_notify_credit_below_1000
        this.is_notify_plan_almost_expire = res?.is_notify_plan_almost_expire
        this.is_notify_out_of_credit = res?.is_notify_out_of_credit
        this.is_notify_plan_expire = res?.is_notify_plan_expire
        this.has_previous_plan = res?.has_previous_plan
        this.is_using_trial = res?.is_using_trial
        this.expired_at = res?.expire_at
        this.sms_unit = res?.sms_unit

        if (_.isUndefined(profile?.is_new_customer) || _.isNil(profile?.is_new_customer)) { // this is legacy customer
          this.user_state = 'pass'
        } else if (_.isNil(res?.expire_at) && _.isNil(res?.current_plan_id)) { // this expired at and current plan id is null
          already_expired = true
          trigger_remainder = false
        } else { // remainder or expired in case has current plain id and expired at
          let date = new Date()
          let expire = new Date(this.expired_at)
          let current_date = moment([date.getFullYear(), date.getMonth() + 1, date.getDate(), date.getHours(), date.getMinutes()])
          let expire_at = moment([expire.getFullYear(), expire.getMonth() + 1, expire.getDate(), expire.getHours(), expire.getMinutes()])
          if (isAfter(expire, date) && expire_at.diff(current_date, 'minute') <= 7200 && expire_at.diff(current_date, 'minute') > 0) {  // check is less than 5 days
            already_expired = false
            trigger_remainder = true
          } else if (expire_at.diff(current_date, 'minute') <= 0) { // check has expired
            already_expired = true
            trigger_remainder = false
          }
        }

        if (res?.is_using_default || !_.isNil(this.is_using_trial)) { //assign popup status
          if (profile?.is_new_customer && !_.isNil(res?.current_plan_id) && this.sms_unit !== 0 && (!res?.is_using_trial && res?.is_using_default)) {
            this.user_state = "fresh_new"
          } else if (profile?.is_new_customer && !_.isNil(res?.current_plan_id) && this.sms_unit !== 0 && (res?.is_using_trial || res?.is_using_default)) {
            this.user_state = "fresh"
          } else if ((!profile?.is_new_customer || profile?.is_new_customer) && this.sms_unit === 0 && this.is_notify_out_of_credit && not_null_current_plan_expired_at) {
            this.user_state = "empty"
            if (!block_modal) {
              this.block_modal = true
            }
          } else if (!profile?.is_new_customer && trigger_remainder && this.is_notify_plan_almost_expire && not_null_current_plan_expired_at && !res?.is_using_trial) {
            this.user_state = "remainder"
          } else if ((!profile?.is_new_customer || profile?.is_new_customer) && already_expired && this.is_notify_plan_expire && this.has_previous_plan) {
            this.user_state = "expired"
          } else if (!profile?.is_new_customer && this.sms_unit < 1000 && this.is_notify_credit_below_1000 && not_null_current_plan_expired_at && !trigger_remainder && !this.is_using_trial && !res?.is_using_default) {
            this.user_state = "less"
          } else {
            this.user_state = 'pass'
          }
        } else {
          this.user_state = 'pass'
        }

        if (this.user_state === "empty") {
          this.modal = !once_modal
        } else if (this.user_state === 'pass') {
          this.modal = false
        } else {
          this.modal = block_modal
        }


      }).catch(() => {
        this.user_state = 'pass'
        this.modal = false
        localStorage.setItem('once_modal', true)
        localStorage.setItem('block_modal', false)
      })

      return this.modal
    },
    setDefaultCloseAllDayPopup() {
      this.is_notify_plan_almost_expire = true
      this.is_notify_plan_expire = true
      this.is_notify_out_of_credit = true
      this.is_notify_credit_below_1000 = true
    },
    closeAllDay() {
      const profile = JSON.parse(localStorage.getItem('profile'))
      const temp_user_status = {
        remainder: 'plan-almost-expire',
        expired: 'plan-expire',
        empty: 'out-of-credit',
        less: 'credit_below_1000'
      }
      if (this.close_all_day) {
        if (!_.isNil(temp_user_status) && !_.isNil(profile?._id)) {
          profileApi.getPopupStatus(profile._id, {"notify_type": temp_user_status[this.user_state]}).then((res) => {
            this.is_notify_plan_almost_expire = res?.is_notify_plan_almost_expire
            this.is_notify_plan_expire = res?.is_notify_plan_expire
            this.is_notify_out_of_credit = res?.is_notify_out_of_credit
            this.is_notify_credit_below_1000 = res?.is_notify_credit_below_1000
          }).catch(() => {
            this.setDefaultCloseAllDayPopup()
          })
        }
      } else {
        this.setDefaultCloseAllDayPopup()
      }
    },
    closeModal(arg) {
      setTimeout(() => {
        localStorage.setItem('block_modal', arg)
        this.sms_unit === 0 ? localStorage.setItem('once_modal', true) : localStorage.setItem('once_modal', false)
      }, 1500)
      this.block_modal = arg
      this.closeAllDay()
    },
    closeDelayEmptyCredit(condition) {
      setTimeout(() => {
        localStorage.setItem('block_modal', false)
        this.sms_unit === 0 ? localStorage.setItem('once_modal', true) : localStorage.setItem('once_modal', false)
        condition === 'by_package' ? this.loading_by_package = false : this.loading_trial = false
      }, 1500)
      this.block_modal = false
      this.closeAllDay()
    },
    actionModalByPackage() {
      this.loading_by_package = true
      const current_plan_id = localStorage.getItem('current_plan_id')
      if (this.$route.name === 'Package') {
        this.closeModal(false)
        return
      } else {
        this.$router.push(`/Subscriptions/Package?current=${current_plan_id}`)
        this.closeDelayEmptyCredit('by_package')
      }
    },
    actionModalTrial() {
      this.loading_trial = true
      if (this.$route.name === 'SMS') {
        this.closeModal(false)
        return
      } else {
        this.$router.push({name: 'SMS'})
        this.closeDelayEmptyCredit('trial')
      }
    },
    //define below method first.
    winWidth: function () {
      setInterval(() => {
        let w = window.innerWidth;

        if (w < 992) {
          this.$store.commit("theme/SET_LAYOUT_TYPE", 'mini-sidebar');
        }
      }, 100);
    }
  },
  mounted() {
    //callback once mounted
    this.winWidth()
  },
  watch: {
    SMSRoute(active) {
      if (active) {
        this.preventReload()
      }
    }
  }
}
</script>

<style lang="scss">
#modal-top-bar {
  p {
    padding: 0 !important;
    margin: 0 !important;
  }

  .font-size-title {
    font-size: 24px;
    @media (max-width: 576px) {
      font-size: 18px;
    }
  }

  .text-danger-modal {
    color: #D30000;
  }

  .less-thousand-credit {
    font-size: 18px;
    text-align: center;

    @media (max-width: 768px) {
      font-size: 14px;
    }
  }

  .content-modal__content-tob-bar {
    width: 400px;
    display: flex;
    justify-content: center;

    .font-break-point {
      width: 100px;
      white-space: nowrap;

      @media (max-width: 576px) {
        width: 82px;
      }
    }

    @media (max-width: 768px) {
      width: 300px;
    }

    @media (max-width: 576px) {
      width: 250px;
    }


    .content-modal-empty {
      font-size: 18px;
      text-align: center;

      @media (max-width: 768px) {
        font-size: 16px;
      }

      @media (max-width: 576px) {
        font-size: 14px;
      }
    }

    .content-modal {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 80%;
      @media (max-width: 768px) {
        width: 100%;
      }


      .content-modal-left__detail, .content-modal-right__detail {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 576px) {
          justify-content: center;
          gap: 20px;
          padding: 0 20px;
        }

        p {
          display: flex;
          gap: 5px;
          font-weight: 600;
          font-size: 18px;

          @media (max-width: 768px) {
            font-size: 16px;
          }

          @media (max-width: 576px) {
            font-size: 12px;
          }
        }

        p:first-child {
          justify-content: flex-start;
        }

        p:last-child {
          justify-content: flex-start;
        }
      }

      .content-modal-right__detail {
        margin: 0 40px;

        @media (max-width: 576px) {
          margin: 0 25px;
        }

        p:last-child {
          justify-content: flex-end !important;
        }
      }
    }
  }

  .btn-breakpoint, .btn-breakpoint-fresh {
    font-size: 16px;
    max-width: 380px;
    width: 100%;

    @media (max-width: 576px) {
      font-size: 12px;
      max-width: 200px;
      width: 100%;
    }

  }

  .action-layout {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 18px;

    .checkout-close-all-day {
      display: flex;
      gap: 8px;
      align-items: center;


      input {
        cursor: pointer;
        position: relative !important;
        appearance: none;
        box-sizing: content-box;
        overflow: hidden;

        &:before {
          content: '';
          display: block;
          box-sizing: content-box;
          width: 12px;
          height: 12px;
          padding: 2px;
          border-radius: 2px;
          border: 1px solid #C7C7C7;
          transition: 0.2s border-color ease;
        }

        &:checked:before {
          background-color: var(--primary-color);
        }

        &:after {
          content: '';
          display: block;
          position: absolute;
          box-sizing: content-box;
          top: 50%;
          left: 50%;
          transform-origin: 50% 50%;
          width: 16px;
          height: 16px;
          border-radius: 100vh;
          transform: translate(-50%, -50%) scale(0);
        }


        &[type="checkbox"] {
          &:before {
            border-radius: 16px / 4;
          }

          //arrow
          &:after {
            width: 14px *0.6;
            height: 16px;
            border-radius: 0;
            transform: translate(-50%, -90%) scale(0) rotate(45deg);
            background-color: transparent;
            box-shadow: 4px 4px 0px 0px white;
          }


          &:checked:after {
            animation: toggleOnCheckbox 0.2s ease forwards;
          }
        }

      }


      @keyframes toggleOnCheckbox {
        0% {
          opacity: 0;
          transform: translate(-50%, -85%) scale(0) rotate(45deg);
        }

        70% {
          opacity: 1;
          transform: translate(-50%, -85%) scale(0.9) rotate(45deg);
        }

        100% {
          transform: translate(-50%, -85%) scale(0.8) rotate(45deg);
        }
      }

      span {
        color: #9F9F9F;
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
        padding-top: 4px;

        @media (max-width: 576px) {
          font-size: 12px;
        }
      }

      input:checked + span {
        color: #2F2E2E;
      }

      margin: 0;
    }

    .button-fresh-layout {
      display: flex;
      gap: 24px;
      width: 80%;

      @media (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        gap: 12px;
      }
    }

    .button-other-layout {
      display: flex;
      width: 100%;
      justify-content: center;
    }
  }


  .action-modal-hint {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    width: 65%;

    @media (max-width: 768px) {
      width: 72%;
      p.action-modal-left {
        white-space: nowrap;
        font-size: 12px !important;
      }
    }

    p {
      font-size: 14px;
    }
  }
}
</style>