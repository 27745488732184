export default [
    {header: "Starterkit", icon: "mdi mdi-dots-horizontal"},
    {
        id: "1",
        icon: "home",
        title: "Starter",
        i18n: "Starter",
        name: "/starter/",
    },
    {
        id: "2",
        icon: "book-open",
        title: "Page",
        i18n: "Page",
        name: "/page/",
    },
];
