<template>
  <div>
    <div class="overlay-background"></div>
    <div class="main-content-modal">
      <div class="sub-content-modal">
        <div class="header-modal">
          <slot name="header"></slot>
          <img src="@/assets/images/icon/xmark.svg" id="x-mark" alt="x-mark" style="cursor: pointer !important;"
               @click="closeModal">
        </div>
        <div class="content-modal" v-if="user_state !== 'expired'">
          <slot name="content"></slot>
        </div>
        <div class="action">
          <slot name="action"></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    modal: [Boolean, String],
    user_state: String
  },
  watch: {
    modal(val) {
      !val && this.autoClose()
    }
  },
  methods: {
    closeModal() {
      try {
        let modal = document.querySelector(".main-content-modal");
        let overlay = document.querySelector('.overlay-background');
        overlay.classList.remove("overlay-background");
        modal.setAttribute("closing", "");
        modal.addEventListener(
            "animationend",
            () => {
              modal.remove("closing");
              modal.classList.remove("main-content-modal");
            },
            {once: true}
        );
        this.$emit('closeModal', false)
      } catch {
      }
    },
    autoClose() {
      setTimeout(() => {
        this.closeModal()
      }, 800)
    }
  }
}
</script>
<style lang="scss" scoped>
.overlay-background {
  position: fixed;
  border-radius: 8px !important;
  background: #ffffff;
  opacity: 0.7;
  z-index: 997;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

@keyframes modal-fade-in {
  0% {
    opacity: 0;
    left: 50%;
    top: 30%;
    transform: translate(-50%, -50%);
  }
  50% {
    opacity: 0.5;
    left: 50%;
    top: 60%;
    transform: translate(-50%, -50%);
  }
  100% {
    opacity: 1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

@keyframes modal-fade-out {
  0% {
    opacity: 1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  50% {
    opacity: 0.5;
    left: 50%;
    top: 60%;
    transform: translate(-50%, -50%);
  }
  100% {
    opacity: 0;
    left: 50%;
    top: 30%;
    transform: translate(-50%, -50%);
  }
}

.main-content-modal[closing] {
  animation: modal-fade-out 0.8s ease-in-out;
}

.main-content-modal {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  width: 730px;
  background-color: #FFF;
  border-radius: 20px;
  box-shadow: 0 1px 5px #0000003F;
  animation: modal-fade-in 0.8s ease-in-out;

  @media (max-width: 768px) {
    width: 630px;
  }

  @media (max-width: 576px) {
    width: 300px;
  }


  .sub-content-modal {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding: 38px 48px;
    gap: 24px;

    .header-modal {
      position: relative;
      align-items: center;
      flex-direction: column;
      width: 100%;
      gap: 24px;
      font-weight: 700;
      display: flex;
      justify-content: center;

      img#x-mark {
        position: absolute;
        right: 0;
        top: 0;
      }
    }

    .content-modal {
      display: flex;
      width: 100%;
      justify-content: center;
    }

    .action {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 100%;
      gap: 24px;

      @media (max-width: 576px) {
        width: 90%;
      }

    }
  }
}
</style>