<template>
  <b-navbar-nav class="ml-auto" :id="landingPage?'language-landing-page':''">
    <!-- -----------------------------------------------------------
      Language Dropdown
    ----------------------------------------------------------- -->
    <b-nav-item-dropdown no-caret right ref="dropdown_language">
      <template #button-content>
        <label class="text-grey mt-1" @click="closeURLCredit">
          <i :class="getCurrentLanguage.lang" class="mr-1"></i> {{ getCurrentLanguage.text }}</label>
      </template>
      <b-dropdown-item v-for="item in languageList" :key="item.iso_code" @click="changeLanguage(item.code)"
      ><i :class="`mr-2 flag-icon flag-icon-${item.iso_code}`"></i>{{ item.name }}
      </b-dropdown-item>
    </b-nav-item-dropdown>
  </b-navbar-nav>
</template>

<script>

import {store} from "@/store";

export default {
  name: "Language",
  props: {
    landingPage: {
      type: Boolean,
      default: false
    },
    closeDropdownProp: Boolean,
    requireUrlCredit: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({}),
  created() {
    const lang = sessionStorage.getItem('lang') || this.getLanguageProfile
    this.$i18n.locale = this.$route.query.locale ? this.$route.query.locale : lang ? lang : 'th'
  },
  computed: {
    getLanguageProfile() {
      return JSON.parse(localStorage.getItem('profile'))?.locale
    },
    getCurrentLanguage() {
      const locale = this.$i18n.locale;
      if (locale === "en") return {lang: "flag-icon flag-icon-us", text: "English"};
      else if (locale === "th") return {lang: "flag-icon flag-icon-th", text: "Thai"};
      else if (locale === "cn") return {lang: "flag-icon flag-icon-cn", text: "Chinese"};
      else if (locale === "vn") return {lang: "flag-icon flag-icon-vn", text: "Vietnam"};
      else if (locale === "my") return {lang: "flag-icon flag-icon-my", text: "Malaysia"};
      else if (locale === "id") return {lang: "flag-icon flag-icon-id", text: "Indonesia"};
      else return {lang: "flag-icon flag-icon-us", text: "English"};
      // return locale;
    },
    languageList() {
      return this.$store.getters["profile/getLanguageList"]
    }
  },
  methods: {
    closeURLCredit() {
      if (this.requireUrlCredit) {
        this.$emit('closeURLCredit', false)
      }
    },
    changeLanguage(locale) {
      this.$i18n.locale = locale;
      sessionStorage.setItem("lang", locale)
      store.dispatch('profile/setLanguage', locale)
    },
  },
  watch: {
    closeDropdownProp(newValue) {
      if (newValue && this.requireUrlCredit) {
        this.$refs.dropdown_language.hide(false)
      }
    },
  }
}
</script>

<style>
#language-landing-page {
  ul.dropdown-menu {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .nav-link {
    padding: 4px 8px !important;
  }

  .nav-link:hover {
    border-radius: 4px;
    color: #19CBACFF !important;
    background-color: rgba(25, 203, 172, 0.05);
    transition: all ease-out 0.2s;
  }

  .dropdown-item:hover, .dropdown-item:focus {
    background-color: rgba(25, 203, 172, 0.1);
    color: #19CBACFF !important;
  }
}

</style>