<template>
  <aside
      class="left-sidebar"
      :class="Sidebar_drawer ? `show-sidebar bg-${SidebarColor}` : `bg-${SidebarColor}`"
  >
    <div :class="`bg-${logoColor} navbar-header`">
      <LogoDark v-if="logoColor === 'white'"/>
      <LogoLight v-else/>
      <span
          class="d-block d-lg-none close-sidebar-btn"
          @click="showMobileSidebar"
      ><i class="ti ti-close close-icon"></i
      ></span>
    </div>

    <VuePerfectScrollbar class="scrlbar">
      <ul class="nav flex-column mb-0">
        <template v-for="(route, i) in routes">
          <!---Menu Title -->
          <li class="nav-item" :key="route.header" v-if="route.header">
            <div class="d-flex nav-small-cap">
              <i :class="route.icon"></i>
              <span class="hide-text">{{ route.header }}</span>
            </div>
          </li>
          <!---Sub Menu -->
          <li class="nav-item" :key="route.title" v-if="route.childern && checkPermissionSubMenu(route.childern)"
              @click="activeSubMenu">
            <a class="nav-link d-flex" v-b-toggle="route.id">
              <!-- <feather :type="route.icon"></feather> -->
              <span
                  class="icon-menu mr-2 ml-1"
                  :style="iconMaskURL(route.icon, $route.path.replace('/' + $route.params.id, '').includes(route.to) ? 'white' : '#212529')"
              ></span>
              <span class="hide-text">{{ $t(route.i18n) || route.title }}</span>
              <i class="ti ti-angle-down dd-arrow ml-auto hide-text"></i>
            </a>
            <b-collapse :id="route.id" accordion="sidebar-accordion"
                        :visible="$route.path.replace('/' + $route.params.id, '').includes(route.name)">
              <ul class="nav flex-column sub-menu">
                <template v-for="(subroute, i) in route.childern"
                          v-if="route.childern && checkPermission(subroute.permissions)">
                  <li class="nav-item " :key="i" @click="checkMobile()">
                    <router-link class="nav-link d-flex" :to="`${subroute.to}`" :class="
                        $route.path.replace('/' + $route.params.id, '').includes(subroute.to)
                        ? 'router-link-exact-active'
                          : ''
                      ">
                      <span class="hide-text ml-4">{{ $t(subroute.title) || subroute.title }}</span>
                    </router-link>
                  </li>
                </template>
              </ul>
            </b-collapse>
          </li>
          <!---Single Menu -->
          <li class="nav-item" :key="i" v-if="!route.childern && !route.header && checkPermission(route.permissions)"
              @click="checkMobile()">
            <router-link :to="`${route.to}`" class="nav-link d-flex" :class="
                $route.path.replace('/' + $route.params.id, '').includes(route.to)
                  ? 'router-link-exact-active'
                  : ''
              ">
              <span
                  class="icon-menu mr-2 ml-1"
                  :style="iconMaskURL(route.icon, $route.path.replace('/' + $route.params.id, '').includes(route.to) ? 'white' : '#212529')"
              ></span>
              <span class="hide-text">{{ $t(route.i18n) || route.title }}</span>
            </router-link>
          </li>
        </template>
      </ul>
    </VuePerfectScrollbar>
  </aside>
</template>

<script>
import LogoDark from "../logo-dark/LogoDark";
import LogoLight from "../logo-light/LogoLight";
import {mapState} from "vuex";
import SidebarData from "./SidebarData";
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import functions from "@/common/functions";

export default {
  name: "VerticalSidebar",
  components: {
    LogoDark,
    LogoLight,
    VuePerfectScrollbar
  },
  data: () => ({
    initialShow: true,
    routes: SidebarData,
    visible: false,
    permission: []
  }),
  computed: {
    ...mapState({
      logoColor: state => state.theme.logoColor,
      Sidebar_drawer: state => state.theme.Sidebar_drawer,
      SidebarColor: state => state.theme.SidebarColor,
    }),
    userInfo() {
      return this.$store.getters["profile/getProfile"]
    }
  },
  mounted() {
    this.permission = JSON.parse(localStorage.getItem('permissions'))
    this.checkSpacialRouter();
  },
  methods: {
    checkMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        this.$store.commit("theme/SET_SIDEBAR_DRAWER", false);
        return true
      } else {
        this.$store.commit("theme/SET_SIDEBAR_DRAWER", true);
        return false
      }
    },
    showMobileSidebar() {
      this.$store.commit("theme/SET_SIDEBAR_DRAWER", false);
    },
    activeSubMenu() {
      this.visible = true
    },
    checkPermission(per) {
      const permission = JSON.parse(localStorage.getItem('permissions'))
      return permission.find(item => {
        return per.includes(item?.module) && item?.actions?.length > 0
      })
    },
    iconMaskURL(name, code) {
      return functions.iconMaskURL(name, code)
    },
    checkPermissionSubMenu(per) {
      return per.find(item => {
        return functions.userCheckPermission(item.permissions, 'tab')
      })
    },
    checkSpacialRouter() {
      let path = this.routes;
      const withdrawal_permission = this.checkPermissionFromList('withdraw', 'action', 'affiliate');
      const hast_permission_for_register = this.checkPermissionFromList('register', 'action', 'affiliate');
      const is_member_affiliate = this.userInfo?.is_member_affiliate;
      if (is_member_affiliate || hast_permission_for_register === undefined) {
        path = path.filter(item => item.to !== "/Affiliate-Register");
      }
      if (is_member_affiliate === false || withdrawal_permission === undefined) {
        path = path.filter(item => item.title !== "Affiliate");
      }
      this.routes = path;
    },
    checkPermissionFromList(permission, type, modules) {
      return functions.userCheckPermission(permission, type, modules)
    },
  }
};
</script>

<style>
.left-sidebar {
  font-size: 16px;

}

.close-icon {
  color: var(--primary-color);
  cursor: pointer;
}

.close-icon:hover {
  color: #0c0d10;
}

.icon-menu {
  width: 20px;
  height: 20px;
}
</style>