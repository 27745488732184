<template>
  <div v-show="QRSetting?.line_open_qr !== null">
    <a href="javascript:void (0)" class="float" @click="activeShowContent">
      <i class="fab fa-line my-float" v-if="!isShow"></i>
      <i class="far fa-times-circle my-float" v-else></i>
    </a>
    <div class="box-content" v-if="isShow">
      <div>
        <div>
          <div class="content-box mt-2">
            <iframe :src="QRSetting?.line_open_qr" class="w-100 "
                    style="height: 480px!important; border:none;"></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "floatButton",
  data() {
    return {
      isShow: false,
      isReadMore: false
    }
  },
  methods: {
    activeShowContent() {
      this.isShow = !this.isShow
      if (this.isShow === false) {
        this.isReadMore = false
      }
    },
  },
  computed: {
    QRSetting() {
      return this.$store.getters['profile/getQRSetting']
    },
  }
}
</script>

<style scoped lang="scss">


.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 17px;
  right: 20px;
  background-color: #0bb7af;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
}

.my-float {
  margin-top: 17px;
  font-size: 26px;
}

.box-content {
  position: fixed;
  right: 40px;
  z-index: 99;
  background: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
  width: 30vw;
  height: 39vw;
  bottom: 5.4rem;
  border-radius: 8px;
  padding: 2rem;
}

.content-box {
  font-size: 13px;
}
</style>