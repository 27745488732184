<template>
  <footer class="p-4 text-center">
    Copyright &copy; <span class="text-primary text-bold" :class="{'landing-page-text':landingPage}">{{
      BrandSetting?.footer_text || 'SMS2PRO'
    }}</span>
    {{ lastVersion || 'v.1.4.8' }}

  </footer>
</template>
<script>
export default {
  name: "Footer",
  props: {
    landingPage: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  computed: {
    lastVersion() {
      return process.env.VUE_APP_ROOT_VERSION
    },
    BrandSetting() {
      return JSON.parse(localStorage.getItem('brand_setting'))
    },
  },
};
</script>
<style scoped>
.landing-page-text {
  color: #19CBACFF !important;
}
</style>
