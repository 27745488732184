<template>
  <b-navbar-brand href="#">
    <!-- Logo icon -->
    <b class="logo-icon">
      <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
      <!-- Light Logo icon -->
      <!--      {{BrandSetting}}-->
      <img v-show="BrandSetting.image.logo"
           :src="BrandSetting.image.logo"
           alt="homepage" height="40px"
      />
    </b>
    <!--End Logo icon -->
    <!-- Logo text -->
    <span class="logo-text">
            <!-- Light Logo text -->
       <img v-show="BrandSetting.image.text_logo"
            :src="BrandSetting.image.text_logo"
            alt="homepage" height="40px"
       />
        </span>
  </b-navbar-brand>
</template>

<script>
export default {
  name: 'LogoDark',
  computed: {
    BrandSetting() {
      return JSON.parse(localStorage.getItem('brand_setting'))
    },
  },
}
</script>
