export default [
    {
        id: "1",
        icon: "data-management",
        title: "SideBarItems.dashboard.text",
        i18n: "SideBarItems.dashboard.text",
        to: "/Dashboard",
        permissions: "dashboards"
    },
    {
        id: "2",
        icon: "subscribe",
        title: "SideBarItems.subscriptions.text",
        i18n: "SideBarItems.subscriptions.text",
        to: "/Subscriptions",
        permissions: "subscriptions"
    },
    {
        id: "3",
        icon: "invoice",
        title: "SideBarItems.allInvoice.text",
        i18n: "SideBarItems.allInvoice.text",
        to: "/Invoice",
        permissions: "all-invoices"
    },
    {
        id: "4",
        icon: "contact-book",
        title: "SideBarItems.contacts.text",
        i18n: "SideBarItems.contacts.text",
        to: "/Contacts",
        permissions: "contacts contact-groups blacklists"
    },
    {
        id: "5",
        icon: "direct",
        title: "SideBarItems.sms.text",
        i18n: "SideBarItems.sms.text",
        to: "/SMS",
        permissions: "quick-send send-using-file campaign-builder sms-templates spams"
    },
    {
        id: "6",
        icon: "affiliate",
        title: "SideBarItems.register_affiliate.text",
        i18n:"SideBarItems.register_affiliate.text",
        to: "/Affiliate-Register",
        permissions: "affiliate"
    },
    {
        id: "7",
        icon: "affiliate",
        title: "Affiliate",
        i18n: "Affiliate",
        name: "/Affiliate/",
        childern: [
            {
                title: "SideBarItems.affiliate_dashboard.text",
                to: "/Affiliate/Affiliate-Dashboards",
                permissions: "affiliate"
            },
            {
                title: "withdrawal.withdrawal_insight",
                to: "/Affiliate/Withdrawal",
                permissions: "affiliate"
            },
            {
                title: "SideBarItems.manage_partner.text",
                to: "/Affiliate/Management-Partner",
                permissions: "affiliate"
            },
        ],
    },
    {
        id: "8",
        icon: "calendar",
        title: "SideBarItems.calendar.text",
        i18n: "SideBarItems.calendar.text",
        to: "/Calendar",
        permissions: "calendar"
    },
    {
        id: "9",
        icon: "link",
        title: "SideBarItems.shortLink.text",
        i18n: "SideBarItems.shortLink.text",
        to: "/Short-Link",
        permissions: "url-trackings"
    },

    {
        id: "10",
        icon:"user",
        title: "SideBarItems.setting.list.senderID",
        i18n: "SideBarItems.setting.list.senderID",
        to: "/Setting/SenderID",
        permissions: "sender-names"
    },
    {
        id: "11",
        icon: "api_1",
        title: "SideBarItems.smsAPI.text",
        i18n: "SideBarItems.smsAPI.text",
        to: "/Developers",
        permissions: "api-documents"
    },
    {
        id: "12",
        icon: "presentation",
        title: "SideBarItems.reports.text",
        i18n: "SideBarItems.reports.text",
        name: "/Reports/",
        childern: [
            {
                title: "SideBarItems.reports.list.reportCreditTopUp",
                to: "/Reports/Credit-TopUp",
                permissions: "credit-topup-reports"
            },
            {
                title: "SideBarItems.reports.list.reportCampaigns",
                to: "/Reports/Campaigns",
                permissions: "campaign-reports"
            },
            {
                title: "SideBarItems.reports.list.otp",
                to: "/Reports/OTP",
                permissions: "otp-reports"
            },
            {
                title: "SideBarItems.reports.list.messageReports",
                to: "/Reports/Message",
                permissions: "message-reports"
            },
            {
                title: "SideBarItems.reports.list.returnCreditReport",
                to: "/Reports/Return-Credit",
                permissions: "credit-refund-reports"
            },
            {
                title: "SideBarItems.reports.list.creditReport",
                to: "/Reports/Credit-Used",
                permissions: "credit-use-reports"
            },
            {
                title: "SideBarItems.reports.list.advancedReport",
                to: "/Reports/Advanced-Report",
                permissions: "advance-reports"
            },
            {
                title: "SideBarItems.reports.list.sessionReport",
                to: "/Reports/Session-Report",
                permissions: "session-reports" //need permission
            },
        ],
    },
    {
        id: "13",
        icon: "contact-us",
        title: "SideBarItems.contactUs.text",
        i18n: "SideBarItems.contactUs.text",
        to: "/Contact-Us",
        permissions: "contact-us"
    },
    {
        id: "14",
        icon: "manual",
        title: "SideBarItems.manual.text",
        i18n: "SideBarItems.manual.text",
        to: "/Manual",
        permissions: "manual-guide"
    },

];
